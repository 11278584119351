import React from "react";

import {
	RoundedButtonContainer,
	roundedButtonStyles,
	AppleLogo,
} from "../sign-in/sign-in.styles";
import appleLogo from "../../assets/appleLogo.svg";
import RoundedButton from "../rounded-button/rounded-button.component";
import { signInWithApple } from "../../firebase/firebase.utils";
import { selectConsents } from "../../redux/consents/consents.selector";
import { useDispatch, useSelector } from "react-redux";
import { showAlertAction } from "../../redux/alert/alert.actions";
import { AgreeToPPAndTCMessage } from "../../redux/alert/alert.example.messages";
import { updateConsentsAction } from "../../redux/consents/consents.actions";
import { selectIsEmbeddedInAndroidIosApp } from "../../redux/metadata/metadata.selector";
import { requestIdTokenAndNonceForSignInWithApple } from "../../utils/CustomReactMessages.utils";
import { setUploadConsentWhenAuthenticatedSuccessfully } from "../../redux/current-user/current-user.actions";

const SignInWithApple = ({
	checkForPPAndTCConsent = false,
	switchToLogIn = () => {},
	signUp = false,
}) => {
	const { PPandTandCConsent } = useSelector(selectConsents);
	const dispatch = useDispatch();
	const isEmbeddedInAndroidIosApp = useSelector(
		selectIsEmbeddedInAndroidIosApp
	);

	return (
		<div>
			<RoundedButtonContainer>
				<RoundedButton
					$FullWidth
					styles={roundedButtonStyles}
					type="button"
					onClick={() => {
						if (checkForPPAndTCConsent) {
							if (PPandTandCConsent === true) {
								dispatch(
									setUploadConsentWhenAuthenticatedSuccessfully(
										true
									)
								);
								if (isEmbeddedInAndroidIosApp) {
									requestIdTokenAndNonceForSignInWithApple();
								} else {
									signInWithApple();
									// .then(res => {
									// 	dispatch(
									// 		uploadConsentsToFirestoreStartAction({
									// 			uid: res.user.uid,
									// 		})
									// 	);
									// });
								}
							} else {
								dispatch(
									updateConsentsAction({ underline: true })
								);
								dispatch(
									showAlertAction({
										...AgreeToPPAndTCMessage,
										buttons: [
											{
												title: "go to log in",
												styles: "background-color:gray;",
												onClick: switchToLogIn,
											},
											{ title: "OK" },
										],
									})
								);
							}
						} else {
							if (isEmbeddedInAndroidIosApp) {
								requestIdTokenAndNonceForSignInWithApple();
							} else {
								signInWithApple().catch(error => {});
							}
						}
					}}
				>
					<AppleLogo
						src={appleLogo}
						alt="logo"
						height="38px"
					></AppleLogo>{" "}
					&nbsp;&nbsp;&nbsp;{signUp ? "SIGN UP" : "LOG IN"} WITH APPLE
				</RoundedButton>
			</RoundedButtonContainer>
		</div>
	);
};

export default SignInWithApple;
