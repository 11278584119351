import React from "react";
import { connect } from "react-redux";

import { addUser } from "../../redux/current-user/current-user.actions";

import {
	StyledLabel,
	RoundedButtonContainer,
	roundedButtonStyles,
	SmallText,
} from "./sign-in.styles";
import { RoundedInput } from "../sign-in-up-form/rounded-input/rounded-input.styles";
import SignInForm, {
	RoundedContainer,
} from "../sign-in-up-form/sign-in-up-form.styles";
import Title from "../title/title.component";
import RoundedButton from "../rounded-button/rounded-button.component";

import { auth } from "../../firebase/firebase.utils";
import { showAlertAction } from "../../redux/alert/alert.actions";
import ForgotPasswordButton from "../forgot-password-button/forgot-password.button";
import TandC_and_PP from "../t&c-and-pp/t&c-and-pp.component";
import SignInWithGoogle from "../sign-in-with-google/sign-in-with-google.component";
import SignInWithApple from "../sign-in-with-apple/sign-in-with-apple.component";
import { selectWindowInnerHeight } from "../../redux/metadata/metadata.selector";
import { signInWithEmailAndPassword } from "firebase/auth";

class SignIn extends React.Component {
	constructor(props) {
		super(props);
		this.state = { email: "", password: "" };
		this.onChange = this.onChange.bind(this);
	}

	onChange(event) {
		const name = event.target.type;
		const previousState = this.state;
		this.setState({ ...previousState, [name]: event.target.value });
	}

	LogIn = () => {
		signInWithEmailAndPassword(
			auth,
			this.state.email,
			this.state.password
		).catch(error => {
			this.props.showAlert({
				title: "Oops.",
				description: error.message,
			});
		});
	};

	render() {
		return (
			<RoundedContainer
				opacity={this.props.opacity}
				{...{
					$acknowledgeHeader: this.props.acknowledgeHeader || false,
					innerHeight: this.props.innerHeight,
				}}
			>
				<Title>
					LOG IN{" "}
					<SmallText onClick={this.props.switchToSignUp}>
						or create account
					</SmallText>
				</Title>
				<SignInForm>
					<StyledLabel>email:</StyledLabel>
					<br />
					<RoundedInput
						type="email"
						id="email"
						value={this.state.email}
						onChange={this.onChange}
					/>
					<br />
					<StyledLabel>password:</StyledLabel>
					<br />
					<RoundedInput
						type="password"
						id="password"
						value={this.state.password}
						onChange={this.onChange}
					/>
					<ForgotPasswordButton />
					<RoundedButtonContainer>
						<RoundedButton
							$FullWidth
							styles={roundedButtonStyles}
							type="button"
							onClick={this.LogIn}
						>
							LOG IN
						</RoundedButton>
					</RoundedButtonContainer>
					<SignInWithGoogle />
					<SignInWithApple />
				</SignInForm>
				<TandC_and_PP />
			</RoundedContainer>
		);
	}
}
const mapStateToProps = state => ({
	isLoggedIn: state.currentUser.isLoggedIn,
	innerHeight: selectWindowInnerHeight(state),
});

const mapDispatchToProps = dispatch => ({
	addUser: () => dispatch(addUser()),
	showAlert: alert => dispatch(showAlertAction(alert)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
