import React from "react";
import Title from "../title/title.component";
import {
	StyledLabel,
	RoundedButtonContainer,
	SignInButtonStyles,
} from "./sign-up.styles";
import SignUpForm, {
	RoundedContainer,
} from "../sign-in-up-form/sign-in-up-form.styles";
import { RoundedInput } from "../sign-in-up-form/rounded-input/rounded-input.styles";
import RoundedButton from "../rounded-button/rounded-button.component";
import { auth, updateUsersProfile } from "../../firebase/firebase.utils";
import { connect } from "react-redux";
import { showAlertAction } from "../../redux/alert/alert.actions";

import { SmallText } from "../sign-in/sign-in.styles";
import SignInWithGoogle from "../sign-in-with-google/sign-in-with-google.component";
import SignInWithApple from "../sign-in-with-apple/sign-in-with-apple.component";
import Consents from "../consents/consents.compnent";
import { selectConsents } from "../../redux/consents/consents.selector";
import { AgreeToPPAndTCMessage } from "../../redux/alert/alert.example.messages";
import {
	updateConsentsAction,
	uploadConsentsToFirestoreStartAction,
} from "../../redux/consents/consents.actions";
import { selectWindowInnerHeight } from "../../redux/metadata/metadata.selector";
import { createUserWithEmailAndPassword } from "firebase/auth";

class SignUp extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			username: "",
			email: "",
			password: "",
		};
		this.onChange = this.onChange.bind(this);
		this.onSignUpClick = this.onSignUpClick.bind(this);
	}

	componentDidMount() {
		this.props.updateConsents({ underline: false });
	}

	onChange(event) {
		const name = event.target.id;
		const previousState = this.state;
		this.setState({ ...previousState, [name]: event.target.value });
	}

	onSignUpClick() {
		const username = this.state.username;
		const { PPandTandCConsent } = this.props;
		if (PPandTandCConsent === true) {
			createUserWithEmailAndPassword(
				auth,
				this.state.email,
				this.state.password
			)
				.then(userCredential => {
					var user = userCredential.user;

					return Promise.all([
						updateUsersProfile({ displayName: username }),
						this.props.uploadConsentsToFirestoreStart({
							uid: user.uid,
						}),
					]);
				})
				.catch(error => {
					this.props.showAlert({
						title: "Oops.",
						description: error.message,
					});
				});
		} else {
			this.props.updateConsents({ underline: true });
			this.props.showAlert({
				...AgreeToPPAndTCMessage,
				buttons: [
					{
						title: "go to log in",
						styles: "background-color:gray;",
						onClick: this.props.switchToLogIn,
					},
					{ title: "OK" },
				],
			});
		}
	}

	render() {
		return (
			<RoundedContainer
				opacity={this.props.opacity}
				{...{
					$acknowledgeHeader: this.props.acknowledgeHeader || false,
					innerHeight: this.props.innerHeight,
				}}
			>
				<Title>
					CREATE ACCOUNT
					<SmallText onClick={this.props.switchToLogIn}>
						or log in
					</SmallText>
				</Title>

				<SignUpForm>
					<StyledLabel>username: </StyledLabel>
					<RoundedInput
						type="username"
						id="username"
						value={this.state.username}
						onChange={this.onChange}
					></RoundedInput>
					<StyledLabel>email: </StyledLabel>
					<RoundedInput
						type="email"
						id="email"
						value={this.state.email}
						onChange={this.onChange}
					></RoundedInput>
					<StyledLabel>password: </StyledLabel>
					<RoundedInput
						type="password"
						id="password"
						value={this.state.password}
						onChange={this.onChange}
					></RoundedInput>
					<Consents />
					<RoundedButtonContainer>
						<RoundedButton
							$FullWidth
							styles={SignInButtonStyles}
							type="button"
							onClick={this.onSignUpClick}
						>
							SIGN UP
						</RoundedButton>
					</RoundedButtonContainer>
					<SignInWithGoogle
						checkForPPAndTCConsent={true}
						switchToLogIn={this.props.switchToLogIn}
						signUp
					/>
					<SignInWithApple
						checkForPPAndTCConsent={true}
						switchToLogIn={this.props.switchToLogIn}
						signUp
					/>
				</SignUpForm>
				{/* <TandC_and_PP Text="By signing up" /> */}
			</RoundedContainer>
		);
	}
}

const mapStateToProps = state => ({
	innerHeight: selectWindowInnerHeight(state),
	...selectConsents(state),
});

const mapDispatchToProps = dispatch => ({
	showAlert: alert => dispatch(showAlertAction(alert)),
	uploadConsentsToFirestoreStart: payload =>
		dispatch(uploadConsentsToFirestoreStartAction(payload)),
	updateConsents: payload => dispatch(updateConsentsAction(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
