import React, { useEffect, useState } from "react";

import subscription_bg from "../../assets/subscription_bg.jpg";
import {
	BackgroundImage,
	SubscriptionPageContainer,
	OffersContainer,
	ZIndexContainer,
	SubscriptionDurationToggle,
	TandCandPPContainer,
} from "./subscriptions-page.styles";
import Offer from "../../components/offer/offer.component";
import { connect, useSelector } from "react-redux";
import {
	selectPackages,
	selectPackagesFromParentApp,
} from "../../redux/subscription/subscription.selectors";
import { getOffersStart } from "../../redux/subscription/subscription.actions";
import {
	getSubscriptionPlatfromAttribute,
	getCurrentSubscriptionProductIDs,
	hasAlreadySubscribedFBFunction,
} from "../../firebase/firebase.utils";
import { selectIsLoggedIn } from "../../redux/current-user/current-user.selector";
import { OfferContainer } from "../../components/offer/offer.styles";
import ShortPromotionVideo from "../../components/short-promotion-video/short-promotion-video.component";
import { addFormilla, removeFormilla } from "../../utils/formilla.utils";
import {
	selectIsEmbeddedInAndroidIosApp,
	selectParentAppOS,
} from "../../redux/metadata/metadata.selector";
import { restoreRCPurchase } from "../../utils/CustomReactMessages.utils";
import { startLoading } from "../../redux/loading-animation/loading-animation.actions";
import { showAlertAction } from "../../redux/alert/alert.actions";
import Toggle from "../../components/toggle/toggle.component";
import TandCandPP from "../../components/t&c-and-pp/t&c-and-pp.component";
import FloatingOffer from "../../components/floating-offer/floating-offer.component";

// The subscriptions disappear after user has created an account because the program checks for the "subscrition platform attribute" on Revenuecat before the "on user create" cloud function sets the "subscription platform attribute"
const SubscriptionPage = ({
	packages,
	getSubscriptionOffersStart,
	isLoggedIn,
	startLoadingAnimation,
	showAlert,
}) => {
	const [currentSubscriptionProducts, setCurrentSubscriptionProducts] =
		useState({});
	const [subscriptionPlatform, setSubscriptionPlatform] = useState("none");
	let [hasAlreadySubscribed, setHasAlreadySubscribed] = useState(false);
	let [mostCurrentProduct, setMostCurrentProduct] = useState(undefined);

	let [billingCycle, setBillingCycle] = useState("monthly");
	const isEmbedded = useSelector(selectIsEmbeddedInAndroidIosApp);
	const packagesFromParentApp = useSelector(selectPackagesFromParentApp);
	const parentAppOS = useSelector(selectParentAppOS);

	useEffect(() => {
		getSubscriptionOffersStart();
		removeFormilla();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		//get the subscriber from revenuecat and get the subscriptions with unsubscribe_detected_at=null

		if (isLoggedIn) {
			//retrieves the ids of products that a user is subscribing to
			getCurrentSubscriptionProductIDs().then(res => {
				const currentSubscriptions = res.data;
				console.log(currentSubscriptions);
				setCurrentSubscriptionProducts(currentSubscriptions);
			});

			getSubscriptionPlatfromAttribute()
				.then(res => {
					setSubscriptionPlatform(res.data.subscriptionPlatform);
				})
				.catch(error => {
					console.error(
						"this is a get subscription paltfrom attriubute function error"
					);
					console.error(error);
					setSubscriptionPlatform("");
				});

			hasAlreadySubscribedFBFunction().then(res => {
				setHasAlreadySubscribed(res.data.hasAlreadySubscribed);
			});
		}
		const addFormillaIfSreenBigEnough = () => {
			if (window.innerWidth > 940) {
				addFormilla();
			}
		};
		window.addEventListener("load", addFormillaIfSreenBigEnough);

		return () => {
			window.removeEventListener("load", addFormillaIfSreenBigEnough);
		};
	}, [isLoggedIn]);

	useEffect(() => {
		if (
			!hasAlreadySubscribed &&
			parentAppOS === "android" &&
			packagesFromParentApp
		) {
			// isEmbedded ? res.data.hasAlreadySubscribed &&

			const hasAFreeTrialOnAndroid = Object.keys(
				packagesFromParentApp
			).some(
				packageID => packagesFromParentApp[packageID].product.introPrice
			);

			setHasAlreadySubscribed(!hasAFreeTrialOnAndroid);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [hasAlreadySubscribed, packagesFromParentApp]);

	//assign metadata from revenucat offers to currentSubscriptionProducts
	useEffect(() => {
		if (currentSubscriptionProducts) {
			packages.forEach(pckg => {
				if (currentSubscriptionProducts[pckg.ProductID]) {
					if (
						!currentSubscriptionProducts[pckg.ProductID].identifier
					) {
						var sub = { ...currentSubscriptionProducts };
						sub[pckg.ProductID] = {
							...currentSubscriptionProducts[pckg.ProductID],
							...pckg,
						};
						setCurrentSubscriptionProducts(sub);
					}
				}
			});

			//getting the newest purchase productID from current subscriptions
			var newestSubscription = undefined;
			Object.keys(currentSubscriptionProducts).forEach(id => {
				if (
					(newestSubscription &&
						new Date(
							currentSubscriptionProducts[id].purchase_date
						) > new Date(newestSubscription.purchase_date)) ||
					newestSubscription === undefined
				) {
					console.log(currentSubscriptionProducts[id]);
					newestSubscription = currentSubscriptionProducts[id];
				}
			});
			if (newestSubscription !== undefined) {
				setMostCurrentProduct(newestSubscription);
			}
			if (newestSubscription?.identifier?.includes("_annually")) {
				setBillingCycle("annual");
			}
		}
	}, [currentSubscriptionProducts, packages]);

	function DisplayLogic({ children }) {
		if (
			subscriptionPlatform === "none" ||
			(isEmbedded && subscriptionPlatform === parentAppOS) ||
			(!isEmbedded && subscriptionPlatform === "stripe") ||
			isLoggedIn === false
		) {
			return children;
		} else if (subscriptionPlatform === "") {
			return null;
		} else {
			//user has already a subscription
			if (subscriptionPlatform === "stripe") {
				return (
					<ZIndexContainer>
						<h1>Mange your subscription on your web browser</h1>
						<p>
							- open{" "}
							<a
								href="https://app.swiss-german-online.com/subscriptions"
								target="_blank"
								rel="noreferrer"
							>
								app.swiss-german-online.com/subscriptions
							</a>
						</p>
						<p> - make sure you are logged in</p>
					</ZIndexContainer>
				);
			} else if (subscriptionPlatform === "android") {
				return (
					<ZIndexContainer>
						<h1>
							Mange your subscription on your{" "}
							{subscriptionPlatform} device
						</h1>
						<p>- Go to your Swiss German app</p>
						<p>- make sure you are logged in</p>
						<p>- Click the menu icon(three stripes, top right)</p>
						<p>- Click "SUBSCRIPTIONS" button</p>
					</ZIndexContainer>
				);
			} else if (subscriptionPlatform === "ios") {
				return (
					<ZIndexContainer>
						<h1>
							Mange your subscription on your{" "}
							{subscriptionPlatform} device
						</h1>
						<p>
							- Go to your{" "}
							<a
								href="https://apps.apple.com/sg/app/swiss-german/id1544245498"
								target="_blank"
								rel="noreferrer"
							>
								Swiss German app
							</a>
						</p>
						<p>- make sure you are logged in</p>
						<p>- Click the Settings icon</p>
					</ZIndexContainer>
				);
			}
			return null;
		}
	}

	return (
		<SubscriptionPageContainer>
			<DisplayLogic>
				<OffersContainer>
					<ShortPromotionVideo />
					<FloatingOffer
						{...{
							hasAlreadySubscribed,
							billingCycle,
						}}
						// packageFromParentApp={
						// 	packagesFromParentApp
						// 		? packagesFromParentApp["VIP"]
						// 		: undefined
						// }
					/>
					<SubscriptionDurationToggle style={{ width: "100%" }}>
						<OfferContainer
							style={{
								cursor: "pointer",
								display: "flex",
								flexDirection: "row",
								justifyContent: "center",
								gap: "10px",
							}}
						>
							yearly {!isEmbedded ? "(-10%)" : null}
							<Toggle
								setBillingCycle={setBillingCycle}
								billingCycle={billingCycle}
							/>
						</OfferContainer>
					</SubscriptionDurationToggle>
					{packages
						.filter(
							pckg => pckg && pckg.BillingCycle === billingCycle
						)
						.map(pckg => {
							return (
								<Offer
									key={pckg.identifier}
									{...pckg}
									currentSubscriptions={
										currentSubscriptionProducts
									}
									{...{
										isEmbedded,
										mostCurrentProduct,
										hasAlreadySubscribed,
									}}
									packageFromParentApp={
										packagesFromParentApp
											? packagesFromParentApp[
													pckg.identifier
											  ]
											: undefined
									}
								/>
							);
						})}

					<div>
						<OfferContainer
							onClick={() => {
								window.open(
									"https://www.swiss-german-online.com/app.html",
									"_blank"
								);
							}}
							style={{ cursor: "pointer" }}
						>
							<h3 style={{ margin: "0px" }}>still not sure?</h3>
							<a
								style={{ margin: "0px", color: "blue" }}
								href="https://www.swiss-german-online.com/app.html"
								target="_blank"
								rel="noreferrer"
							>
								learn more
							</a>
						</OfferContainer>
						{!isEmbedded ? (
							<a
								href="https://app.german.dog/"
								target="_blank"
								rel="noreferrer"
							>
								<OfferContainer
									style={{
										color: "blue",
									}}
								>
									learn standard German
								</OfferContainer>
							</a>
						) : null}
						{isEmbedded ? (
							<OfferContainer
								onClick={() => {
									startLoadingAnimation();
									if (isLoggedIn) {
										restoreRCPurchase();
									} else {
										showAlert({
											title: "Sign in",
											description:
												"You have to sign in first to restore a purchase.",
											redirectTo: "/sign-in-up",
										});
									}
								}}
								style={{ cursor: "pointer" }}
							>
								<h3
									style={{
										margin: "0px",
										color: "#3A3B3C",
									}}
								>
									restore purchase
								</h3>
							</OfferContainer>
						) : null}
					</div>
				</OffersContainer>
			</DisplayLogic>
			<TandCandPPContainer>
				<TandCandPP Text={"By subscribing"} />
			</TandCandPPContainer>

			<BackgroundImage
				src={subscription_bg}
				alt="swiss german subscription background"
			/>
		</SubscriptionPageContainer>
	);
};

const mapStateToProps = state => ({
	packages: selectPackages(state),
	isLoggedIn: selectIsLoggedIn(state),
});

const mapDispatchToProps = dispatch => ({
	getSubscriptionOffersStart: () => dispatch(getOffersStart()),
	startLoadingAnimation: () => dispatch(startLoading()),
	showAlert: payload => dispatch(showAlertAction(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionPage);
